import DataStorageProperties from '../abstract/data-storage-properties';
import bool from '../abstract/properties/property-bool';
import date from '../abstract/properties/property-date';
import int from '../abstract/properties/property-int';
import price from '../abstract/properties/property-price';
import string from '../abstract/properties/property-string';

const charityProperties = DataStorageProperties.create({
  id: int({
    primaryKey: true,
    table: { hidden: true },
    form: { ignore: true },
  }),
  code: string({
    maxLength: 50,
  }),
  name: string({
    titleKey: true,
    maxLength: 50,
    searchable: true,
  }),
  discount: price({
    max: 100,
    min: 0,
  }),
  uses: int({
    defaultValue: 1,
  }),
  used: int({
    defaultValue: 0,
  }),
  unlimited: bool({
    defaultValue: false,
  }),
  created: date({}),
  modified: date({}),
  expires: date({}),
});

export default charityProperties;
