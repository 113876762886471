/**
 * The base URL of the admin CRUD REST API.
 *
 * @type {string}
 */
export const API_BASE_URL =
  process.env['VUE_APP_API_BASE_URL'] ||
  '/admin-api' ||
  undefined;

/**
 * The URL where the app is running.
 *
 * @type {string}
 */
export const HOST =
  process.env['VUE_APP_HOST'] ||
  'https://dev.showtimes.sk' ||
  undefined;
